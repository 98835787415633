.Requirements {
    margin-top: 4rem;

    button {
        font-size: 0.8rem;
        max-width: 20%;
        margin-right: 1rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    p {
        margin-top: 0;
    }
}