@import "colors";

.CardContact{
    display:flex;
    flex-flow: row wrap;
    background-color: $white;
    justify-content: space-between;  
    width: 100%;
    padding: 2rem;
    text-align: center;

    .containerLeft{
        order: 1;
        flex-grow:1;
        flex-shrink: 1;
        min-width: 20rem;
        width: 400px;
        display: flex;
        flex-direction: column;
        text-align:  center;
        justify-content: center;
        color: $lp-color5;
        // background: red;

    }

    .containerRight{
        order: 2;
        flex-grow: 1;
        flex-shrink: 1;
        min-width: 20rem;
        width: 400px;
        display: flex;
        flex-flow: row wrap;
        text-align:  center;
        align-items: center;
        justify-content: center;

        h1{
            
            // font-weight:     bold;
            font-size: 1.5rem;
            padding-bottom: 1rem;
        }
        // background-color: red;
        // background-color: blue;
       
    }

}