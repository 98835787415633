.Row {
    display: flex;
    
    & > * {
        flex: 1 1 auto;
    }
}

.Equal {
    & > * {
        flex: 1 1 0;
    }
}

.MarginBottom {
    margin-bottom: 2rem;
}