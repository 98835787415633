@import "colors";

.wrapper{
    margin-top: 5rem;
}
.antTabs button{
    margin-right: 2rem;
}
@media only screen and (max-width: 767px){
    .antTabs button{
        margin-right: 0.2rem;
    }
  }