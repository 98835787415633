@import "colors";

.GeneralInfo {
    .InfoCard {
        display: inline-block;
        margin-right: 2rem;

        p {
            margin: 0;
        }
    }    
}