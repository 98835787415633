.GeneralInformationForm {
    width: 100%; 

    .InputGroup {
        margin-bottom: 1rem;
    }
    
    label {
        display: inline-block;
        width: 10rem;
    }

    input {
        width: auto;
    }
}