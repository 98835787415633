@import "colors";

.CardIntro {
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap-reverse;
    flex-flow: row wrap-reverse;
    
    // padding: 20px;
    width: 100%;
    justify-content: space-between;
    // position: relative;
    // flex-direction: row;
    // text-align: center;



.coverimg{
    width: 100% !important;
    max-width: 100% !important;
    margin-right: 0px !important;
    order: 2;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
}
    .containerRight{
        // background: purple;
        order: 2;
        flex-grow: 3;
        flex-shrink: 1;
        min-width: 400px;
        flex-wrap: wrap;
        min-width: 20rem;
      
        display: flex;
        flex-direction: row;
        text-align:  right;
        justify-content: space-between;

    }

.wrapper{
   text-align: center;
    background:  url(../../../../assets/images/u4oo8cgyah56.jpg)  no-repeat scroll 0 0;
    color: #fff;
    background-position: center center;
    background-size: cover;
    background-attachment: fixed;
    padding: 2rem 1rem 2rem 1rem;
}

.wrapper img{
border-radius: 10px;
max-width: 70% !important;
margin-right: 0px !important;
}

}