@import "colors";

  .footer {
    text-align: center;
    display: flex;
    flex-flow: row wrap;
    background-color: rgb(7, 71, 166);
    // padding: 3em 1em;
    font-size: 15px;
    img{
      margin: 0!important;
    }
  }
  
  .footer > * {
    flex:  1 100%;
  }
  
  .footer__addr {
    margin-right: 20px;
  
  }
  
  .footer__logo {
    max-width: 180px;
  }
  
  .footer__addr h2 {
    margin-top: 1.3em;
    font-size: 15px;
  }
  
  .nav__title {
    font-size: 15px;
  }
  
  .footer address {
    font-style: normal;
    color: $white;
  }
  
  .footer__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 10em;
    background-color: hsl(227, 12%, 30%);
    border-radius: 4px;
    margin: 0.6em 0;
    font-size: 1rem;
    padding: 10px 16px;
    color: #fff;
  }
  
  .footer ul {
    list-style: none;
    padding-left: 0;
  }
  
  .footer li {
    color: $white;
    line-height: 2em;
  }
  
  .footer a {
    text-decoration: none;
  }
  
  .footer__nav {
      margin-top: 2rem;
      display: flex;
      flex-flow: row wrap;
  }
  
  .footer__nav > * {
    flex: 1 50%;
    margin-right: 20px;
  }
  
  .nav__ul a {
    color: $white;
  }
  
  .nav__ul__extra {
    column-count: 2;
    column-gap: 20px;
  }
  
  .legal {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    font-size: 12px;
    color: $white;
  }
  
  .legal span {
    display: flex;
    text-align: center;
    align-items: center;
  }
  
  .legal span a {
      text-decoration: underline;
      color: $white;
    }
  
  .legal__links span::before {
    content: "|";
    margin: 0 8px;
  }
  
  @media screen and (min-width: 390px) {
    .legal span {
      margin-left: auto;
    }
  }
  
  @media screen and (min-width: 40.375em) {
    .footer {
      padding: 3em 4em;
    }
    
    .footer__nav > * {
      flex: 1;
    }
    
    .nav__item__extra {
      flex-grow: 2;
    }
    
    .footer__addr {
      color : $white;
      flex: 1 0px;
    }
    
    .footer__nav {
      flex: 2 0px;
    }
  }
  .footerButtom{
    text-align: center;
    color: $white;
    padding: 1.5rem;
    background-color: #1e2122;
  }
  .footerButtom p{
    margin: 0px;
  }