@import "colors";

.Content {
    margin-top: 4rem;
    width: 100%;
    color: $grey5;

    @media screen and (min-width: 501px){
        margin-top: 1rem;
    }

    h2 {
        font-variant: small-caps;
        font-weight: normal;
        font-size: 0.8rem;
    }
}