/* max-width constrains the width of our carousel to 550, but shrinks on small devices */
.carousel__wrapper{
    min-height: 40rem;
    padding-top: 9rem;
    
}
.carousel__container {

  max-width: 1200px;
  margin: auto;
 
  position: relative;
  img{
    width: 228px;
    margin-right:0px !important;
}
button{
    color:#347cdb;
    font-size: 2.4rem;
    background: none;
    border: none;
}
}

/* gives us the illusion of a "centered" slide */
.carousel__slider {
  padding-left: 37%;
  padding-right: 37%;
}

/* gives us the illusion of spaces between the slides */
.carousel__inner-slide {
  display: inline;
  width: calc(100% - 33px) !important;
  height: calc(100% - 33px) !important;
  left: 10px;
  top: 10px;
  text-align: center;
  background-image: url('/../../../assets/iphonebg.png');
}

.screenshot-app-bg {
    position: absolute;
    z-index: 100;
   
    left: calc(50% - 146.4px);
    top: calc(50% - 340.5px);
}
.screenshot-app-bg img {
    width: calc(100% - 33px);
}
@media only screen and (max-width: 767px){
  .carousel__slider {
    padding-left: 0;
    padding-right: 0;
  }
  .screenshot-app-bg img {
    display: none;
}
}
.button__container{
    width: 100%;
    margin-top: 7rem;
    text-align: center;
    
}