@import "colors";

.Toolbar {
    height: 64px;
    top: 0;
    position: fixed;
    width: 100%;
    background-color: $lp-color6;
    font-size: 1em;
    z-index: 101;

    box-shadow: 0 4px 10px -10px black;

    .NavigationItems {
        display: flex;
        flex-flow: row;
        padding: 0;
        height: 64px;
        justify-content: center;
        align-content: center;
        
        a {
            text-decoration: none;
        }
    }
}

