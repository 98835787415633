@import "colors";

.ModuleTeachers {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    & > * {
        width: auto;
    }

    img {
        object-fit: cover;
        width:7rem;
        height:7rem;
        border-radius: 50%;
        border: 1px solid $grey2;
    }

    figcaption {
        text-align: center;

        h3 {
            font-size: 1rem;
            font-weight: bold;
        }

        p {
            margin: 0;
        }
    }
}