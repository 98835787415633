// The entire design of the main application is built around five shades of the same color:
//   $color1: lightest
//   $color2: light
//   $color3: normal
//   $color4: dark
//   $color5: darkest
$color1: #5F87E3;
$color2: #214FBB;
$color3: #347cdb;
$color4: #1D46A5;

$color5: #1976d2;

// $color1: #E6F4FF;
// $color2: #8DA0D0;
// $color3: #6A86B8;
// $color4: #3A4C70;
// $color5: #38425D;


// The entire design of the landing page is built around five shades of the same color:
//   $color1: lightest
//   $color2: light
//   $color3: normal
//   $color4: dark
//   $color5: darkest

$lp-color1: #E6F4FF;
$lp-color2: #8DA0D0;
$lp-color3: #6A86B8;
$lp-color4: #3A4C70;
$lp-color5: #38425D;
$lp-color6: #347cdb;
$lp-color7: #002347;


// light to dark
$grey1: #eeeeee;
$grey2: #cccccc;
$grey3: #999999;
$grey4: #666666;
$grey5: #333333;

// base colors
$white: white;
$lightgrey: #f2f2f2;
$black: black;
$success: #8BC34A;
$success-hover: #ADE56C;
$failed: #F44336;
