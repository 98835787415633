img {
    // float: right;
    margin-right: 50px;
    max-width: 300px;
    vertical-align: top;
}
.form {
    display: inline-block;
    
    .Key {
        display: inline-block;
        width: 10rem;
    }
}
.Controls{
    //padding: 10px; enable when other textfield is used
    display: flex;
    flex-direction: row;
    padding: 10px;
    margin: 10px;
    .Control{
        margin: 10px;
    }
}
@media (max-width: 899px) {
    .Controls{
        flex-direction: column;
    }
}