.Col {
    display: flex;
    flex-flow: column;
    
    & > * {
        flex: 1 1 auto;
    }
}

.Equal {
    & > * {
        flex: 1 1 0;
    }
}