@import "colors";

.CardIntro {
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap-reverse;
    // flex-flow: row wrap-reverse;
    
    // padding: 20px;
    width: 100%;
    justify-content: space-between;
    // position: relative;
    // flex-direction: row;
    // text-align: center;


    .containerLeft{
        // background: pink;
        order: 1;
        flex-grow:1;
        flex-shrink: 1;
        min-width: 20rem;
        width: 400px;
        display: flex;
        flex-direction: column;
        text-align:  center;
        justify-content: center;
        color: $lp-color5;
        // background: red;

        h1{
            
            font-weight: bold;
            font-size: 3rem;
        }
        h2{

            font-weight: normal;

        }
        h3{
            // background: yellow;
            font-weight: normal;
            margin-bottom: 2rem;
        }
        p{
            // background: red;
            width: 100%;
            max-width: 400px;
            font-weight: normal;
            margin-bottom: 2rem;
            align-self: center;
        }

    }

.coverimg{
    width: 100% !important;
    max-width: 100% !important;
    margin-right: 0px !important;
    order: 2;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
}
    .containerRight{
        // background: purple;
        order: 2;
        flex-grow: 3;
        flex-shrink: 1;
        min-width: 400px;
        flex-wrap: wrap;
        min-width: 20rem;
      
        display: flex;
        flex-direction: row;
        text-align:  right;
        justify-content: space-between;

    }
.featureArea{
        text-align: center;
        width: 100%;
        padding-right: 25px;
        padding-left: 25px;
        margin-right: auto;
        margin-left: auto;
    }
.featuresBox{
    
    background: rgba(0, 0, 0, 0)  url(../../../../assets/images/fetured-app-icon-bg.png)  no-repeat scroll 0 0;
    padding: 42px 0 0;
    position: relative;
    background-size: 100% 100%;
}
.featuresBox h3 {
    color: rgb(255, 255, 255);
    letter-spacing: 2px;
    line-height: 30px;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0 100px;
}
.featureAppIcon {
    bottom: -52px;
    display: block;
    margin: 0 auto;
    overflow: hidden;
    padding: 8px 0;
    position: relative;
    width: 370px;
}
.featureAppIcon a{
    float: left;
    background:$lp-color6;
    box-shadow: 0 0 5px #2a3339;
    color: $white;
    display: block;
    font-size: 45px;
    height: 100px;
    padding: 26px 0;
    -webkit-transition: color 0.3s ease 0s, -webkit-transform 0.5s ease 0s;
    transition: color 0.3s ease 0s, -webkit-transform 0.5s ease 0s;
    transition: transform 0.5s ease 0s, color 0.3s ease 0s;
    transition: transform 0.5s ease 0s, color 0.3s ease 0s, -webkit-transform 0.5s ease 0s;
    width: 110px;
}
.featureIconLeft {
    -webkit-transform: perspective(200px) rotateY(
-12deg
);
    transform: perspective(200px) rotateY(
-12deg
);
    -webkit-transform-origin: right center 0;
    /* transform-origin: right center 0; */
    width: 130px;
}
.featureIconLeft:hover{
    color:$black;
    -webkit-transform: perspective(200px) rotateY(
        -1deg
        );
            transform: perspective(200px) rotateY(
        -1deg
        );
}
.featureIconRight {
    z-index: 0;
    -webkit-transform: perspective(200px) rotateY(
12deg
);
    transform: perspective(200px) rotateY(
12deg
);
    -webkit-transform-origin: left center 0;
    transform-origin: left center 0;
    width: 130px;
}
.featureIconRight:hover {
    color:$black;
    -webkit-transform: perspective(200px) rotateY(
0deg
);
    transform: perspective(200px) rotateY(
0deg
);
    -webkit-transform-origin: left center 0;
    transform-origin: left center 0;
    width: 130px;
}

.featureIconMiddle {
    z-index: 10000;
}
.featureIconMiddle:hover{
    transform:  perspective(200px) scale(1.1);
}
}