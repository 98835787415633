@import "colors";
.Edit{
  position: absolute;
  right: 4%;
  cursor: pointer;
  font-size: 1.3rem;;
}
.Card {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  position: relative;
  background-color: $white;
  text-align: left;
  margin: 0 1rem 1rem;
  overflow-x: hidden;
  border: none;
  .Title {
    display: flex;
    justify-content: space-between;
    border: none;
    height: 3rem;
    font-size: 1rem;
    line-height: 1.2rem;
    padding: 1rem 0.5rem 0;

    // .grayTitle {
    //   // background-color: #f2f2f2;
    //   // border: 0.5px solid $color3;
    // }

    h1 {
      font-size: 2rem;
      font-weight: normal;
    }
  }

  .Edit:hover {
    color: $color3;
  }

  .Body {
    width: 100%;
    position: relative;
    padding: 1.5rem 1rem;
  }
  header{
    border: none;
    padding: 10px;
  }
}

.removePadding{
  padding: 0px !important;
}

.removeBoxShadow{
  box-shadow: none !important;
}