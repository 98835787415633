@import "colors";

.NavigationItem {
    margin: 0 20px;
    display: flex;
    align-items: center;
    height: 64px;
    color: $lp-color1;

    .Text {
        font-size: 1.2em;
        text-align: left;
        transition: all 0.15s ease-in-out;
    }

    &:hover, &.Active {
        color: $white;
        font-weight: bold;
    }
}
