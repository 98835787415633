@import "colors";

.cardAboutAIM{
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    background-color: $lp-color1;
    justify-content: space-evenly;
    text-align: left;
    color: $lp-color5;

    .containerLeft{
        // background: red;
        display: flex;
        flex-flow: column wrap;
        width: 100%;
        max-width: 40rem;
        text-align:  left;
        justify-content: space-evenly;

        ul{
            margin-left: 1rem;
            text-align: left;
        }

    }
    .containerRight{
        // background: blue;
        display: flex;
        flex-flow: column wrap;
        width: 100%;
        max-width: 35rem;
        text-align:  center;
        justify-content: space-evenly;

        video{
            max-width: 100%;
        }
    }





    .Title {
        justify-content: space-between;
        font-size: 4rem;
        line-height: 2rem;
        float: right;

    }

    .footer {
        display: flex;
        height: 4rem;
        font-size: 2rem;
        line-height: 2rem;
        padding: 2rem;
    }
}