@import 'colors';

.Login {
    display: flex;
    flex-flow: column;
    justify-content: center;
    max-width: 350px;
    margin: 64px auto 0;
    position: relative;

    .Avatar {
        width: 70%;
        height: auto;        
        margin: 3em auto 3em;

        img {
            width: 100%;
            height: auto;
            border: 1px solid $white;
            border-radius: 50%;
        }
    }

    a {
        text-decoration: none;
        color: $grey5;
    }

    .ChangeForm {
        width: 100%;
        text-align: center;
        font-weight: bold;
        color: $grey5;
         
        &:hover {
            cursor: pointer;
            color: $grey4;
        }
    }
}