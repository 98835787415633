@import "colors";

.cardAIMDes{
    display: flex;
    flex-flow: row wrap-reverse;
    background-color: $white;
    color: $lp-color5;
    
    div {
        padding: 25px;
        min-height: 250px;
        width: 50%;
        @media screen and (max-width: 800px) {
            width: 100%;
        }    
    }

    .containerLeft{
        text-align: center;
        margin-top: 8rem;
        h1 {
            margin-bottom: 25px;
            text-align: center;
        }

        img{
          
           
            margin: 0 auto;
            text-align: center;
        }
    }
    .containerRight{
        margin-top: 4rem;
        flex-grow:1;
        // flex-shrink: 1;
        // min-width: 20rem;
        // width: 400px;
        display: flex;
        flex-direction: column;
        text-align:  center;
        justify-content: center;
        color: $lp-color5;
        // background: red;
        ul{
            // margin-left: 1rem;
            text-align: left;
        }

    }

    // .Title {
    //     justify-content: space-between;
    //     font-size: 4rem;
    //     line-height: 2rem;
    //     float: right;

    // }

    // .footer {
    //     display: flex;
    //     height: 4rem;
    //     font-size: 2rem;
    //     line-height: 2rem;
    //     padding: 2rem;
    // }
}