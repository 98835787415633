@import "colors";

input, select {
    padding: 0.5rem;
    border-radius: 0.3rem;
    border: 1px solid $grey3;
}
.Textarea{
    font-size: 1.2rem;
    padding: 0.5rem;
    rows: 10;
    background-color: $white;
    border-radius: 0 5px 5px 0;
    border: 1px solid $grey3;
    flex: 1 1 0;
}