@import "colors";

.Button {
  border: none;
  padding: 0.5rem;
  background-image: linear-gradient($color4, $color5);
  font-weight: normal;
  color: $white;
  border-radius: 0;
  margin: 0;

  cursor: pointer;

  &:hover {
    background-image: linear-gradient($color3, $color5);
  }
}

.Light {
  box-sizing: content-box;
  border: 1px solid $color3;

  &:hover {
    background-image: linear-gradient($color5, $color3);
    color: $color3;
  }
}
