@import "colors";

.RegisterForm {
    width: 100%;
    display: flex;
    flex-flow: column;
    min-height: 240px;

    &>* {
        margin-bottom: 1em;
        text-align: center;
    }

    .Error {
        color: $failed; 
    }

    .Success {
        color: $black;
    }
}
